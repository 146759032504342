// consumer_journey_personalCreateOrder
const __request = require(`./__request/__request_contentType_json`);
const consumer_journey_personalCreateOrder = (pParameter) => {
    if (!pParameter) pParameter = {};
    let enableErrorAlert = true
    if (pParameter.enable_error_alert !== undefined) {
        enableErrorAlert = pParameter.enable_error_alert
    }
    const p = {
        method: 'post',
        urlSuffix: '/consumer/journey/personalCreateOrder',
        data: pParameter,
        enable_error_alert: enableErrorAlert // 临时隐藏
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_journey_personalCreateOrder;